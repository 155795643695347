import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`SuperBot 2018 featured a variety of presentations from experts in the chatbot and voice space.`}</p>
    <p>{`Leaders from Slack, IBM, Hubspot, Reprise, PullString, Assist, Woebot, VoiceSci, and Volley shared their insights into how to build engaging user experiences in conversational interfaces.`}</p>
    <p>{`Below are the presentations from the conference.`}</p>
    <h2>{`Designing Engaging Conversations with Stephane Nguyen of Assist`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/qKiAjJqK-94?feature=oembed" width="1200"></iframe>
    <h2>{`Building Computer Conversations with Oren Jacob of PullString`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Y1G2EWfnUAc?feature=oembed" width="1200"></iframe>
    <h2>{`Personality in Conversations with Rob Lamberson of Reprise`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/9wRVLXq4ums?feature=oembed" width="1200"></iframe>
    <h2>{`Woebot with Alison Darcy`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/V0TiaqkerK8?feature=oembed" width="1200"></iframe>
    <h2>{`Conversational Marketing with Connor Cirillo of Hubspot`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/hHCI7OvW8Bs?feature=oembed" width="1200"></iframe>
    <h2>{`Building Engaging Voice Experiences with Marcell Pelcher of VoiceSci`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/ZtULeMPDSxE?feature=oembed" width="1200"></iframe>
    <h2>{`Voice Games with Max Child of Volley`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/b1aZzG0s5wQ?feature=oembed" width="1200"></iframe>
    <h2>{`Building Bots with IBM Watson with Anamita Guha`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/AHv8VHRose4?feature=oembed" width="1200"></iframe>
    <h2>{`Designing Great Slack Bots with Bear Douglas`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/kr_p8p2903U?feature=oembed" width="1200"></iframe>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      